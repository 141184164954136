import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/common/v5/layout';
import Hero from '@/components/research/hero';
import Areas from '@/components/research/areas';
import Prefooter from '@/components/research/pre-footer';
import SEO from '@/components/seo';

const ResearchPage = ({ data }) => {
  const { researchHeroSection, areasOfResearchSections, preFooter } =
    data.sanityResearchPage;

  return (
    <Layout>
      <div className="overflow-x-hidden">
        <Hero researchHeroSectionData={researchHeroSection} />
      </div>
      <div className="bg-white">
        <Areas areasData={areasOfResearchSections} />
      </div>
      {/* <div className="bg-violet-400">
        <Prefooter preFooterData={preFooter} />
      </div> */}
    </Layout>
  );
};

export const query = graphql`
  query sanityResearchPage {
    sanityResearchPage {
      seo {
        metaTitle
        metaDescription
        image {
          asset {
            gatsbyImageData(width: 1280)
            url
            localFile(width: 1280) {
              publicURL
              childImageSharp {
                fixed(width: 1280) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }

      researchHeroSection {
        _rawTitle(resolveReferences: { maxDepth: 10 })
        _rawSubtitle(resolveReferences: { maxDepth: 10 })
        _rawText(resolveReferences: { maxDepth: 10 })

        ctaLinksV2 {
          ... on SanityExternalLink {
            _type
            label
            url
          }
          ... on SanityInternalLink {
            isCta
            label
            slug {
              current
            }
            _type
          }
        }

        carouselImages {
          _rawTitle(resolveReferences: { maxDepth: 10 })
          _rawSubtitle(resolveReferences: { maxDepth: 10 })

          image {
            asset {
              gatsbyImageData(width: 600)
              url
            }
          }
          imageAlt
          link
        }
      }

      areasOfResearchSections {
        _rawTitle(resolveReferences: { maxDepth: 10 })
        _rawText(resolveReferences: { maxDepth: 10 })

        sections {
          ... on SanityAreasOfResearchSectionOne {
            _type
            navTitle
            title
            _rawSubtitle(resolveReferences: { maxDepth: 10 })
            _rawDescription(resolveReferences: { maxDepth: 10 })

            images {
              title
              imageAlt
              _rawInfoText(resolveReferences: { maxDepth: 10 })

              image {
                asset {
                  gatsbyImageData(width: 400)
                  url
                }
              }
            }

            infoSections {
              title
              _rawText
            }

            _rawSummaryTitle
            _rawSummary

            _rawCtaTitle
            ctaLinks {
              ... on SanityExternalLink {
                _type
                label
                url
              }
              ... on SanityInternalLink {
                isCta
                label
                slug {
                  current
                }
                _type
              }
            }
          }
          ... on SanityAreasOfResearchSectionTwo {
            _type
            navTitle
            title
            _rawSubtitle(resolveReferences: { maxDepth: 10 })
            _rawDescription(resolveReferences: { maxDepth: 10 })

            _rawText(resolveReferences: { maxDepth: 10 })

            mobileImage {
              asset {
                gatsbyImageData(width: 600)
                url
              }
            }
            desktopImage {
              asset {
                gatsbyImageData(width: 1280)
                url
              }
            }
            imageAlt

            chartsMobileImage {
              asset {
                gatsbyImageData(width: 400)
                url
              }
            }
            chartsDesktopImage {
              asset {
                gatsbyImageData(width: 1280)
                url
              }
            }
            chartsImageAlt

            summary {
              _rawTitle(resolveReferences: { maxDepth: 10 })
              _rawText(resolveReferences: { maxDepth: 10 })

              mobileImage {
                asset {
                  gatsbyImageData(width: 600)
                  url
                }
              }
              desktopImage {
                asset {
                  gatsbyImageData(width: 1152)
                  url
                }
              }
              imageAlt
            }

            _rawCtaTitle
            ctaLinks {
              ... on SanityExternalLink {
                _type
                label
                url
              }
              ... on SanityInternalLink {
                isCta
                label
                slug {
                  current
                }
                _type
              }
            }
          }
          ... on SanityAreasOfResearchSectionThree {
            _type
            navTitle
            title
            _rawSubtitle(resolveReferences: { maxDepth: 10 })
            _rawDescription(resolveReferences: { maxDepth: 10 })

            _rawText(resolveReferences: { maxDepth: 10 })

            mobileImage {
              asset {
                gatsbyImageData(width: 600)
                url
              }
            }
            desktopImage {
              asset {
                gatsbyImageData(width: 1280)
                url
              }
            }
            imageAlt

            summary {
              _rawTitle(resolveReferences: { maxDepth: 10 })
              _rawText(resolveReferences: { maxDepth: 10 })
            }
          }

          ... on SanityAreasOfResearchSectionFour {
            _type
            navTitle
            title
            _rawSubtitle(resolveReferences: { maxDepth: 10 })
            _rawDescription(resolveReferences: { maxDepth: 10 })

            results {
              _rawTitle(resolveReferences: { maxDepth: 10 })
              mobileImage1 {
                asset {
                  gatsbyImageData(width: 600)
                  url
                }
              }
              desktopImage1 {
                asset {
                  gatsbyImageData(width: 1280)
                  url
                }
              }
              imageAlt1
              mobileImage2 {
                asset {
                  gatsbyImageData(width: 600)
                  url
                }
              }
              desktopImage2 {
                asset {
                  gatsbyImageData(width: 1280)
                  url
                }
              }
              imageAlt2
              _rawFooterText(resolveReferences: { maxDepth: 10 })
            }

            summary {
              _rawTitle(resolveReferences: { maxDepth: 10 })
              _rawTextLeft(resolveReferences: { maxDepth: 10 })
              _rawTextRight(resolveReferences: { maxDepth: 10 })
            }

            _rawCtaTitle
            ctaLinks {
              ... on SanityExternalLink {
                _type
                label
                url
              }
              ... on SanityInternalLink {
                isCta
                label
                slug {
                  current
                }
                _type
              }
            }
          }

          ... on SanityAreasOfResearchSectionFive {
            _type
            navTitle
            title
            sections {
              title

              links {
                ... on SanityExternalLink {
                  _type
                  label
                  url
                }
                ... on SanityInternalLink {
                  isCta
                  label
                  slug {
                    current
                  }
                  _type
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ResearchPage;

export const Head = ({ data }) => {
  const { seo } = data.sanityResearchPage;

  return (
    <SEO
      title={seo.metaTitle}
      description={seo.metaDescription}
      image={seo && seo.image ? seo.image.asset.localFile.publicURL : null}
      imageWidth={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.width
          : null
      }
      imageHeight={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.height
          : null
      }
    />
  );
};
