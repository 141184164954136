import React from 'react';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';
import { Link } from 'gatsby';
import { PortableText } from '@portabletext/react';
import { trackEvent } from '@/utils/amplitude-tracking';

export const portableTextHeading = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <span className="title-hl">{children}</span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const CTALink = ({ link, className }) => {
  if (link._type === 'internalLink') {
    // trim the slash from the beginning of the slug if there is one
    const slug = link.slug.current.startsWith('/')
      ? link.slug.current.slice(1)
      : link.slug.current;

    return (
      <Link
        onClick={() => trackEvent(link)}
        to={`/${slug}`}
        className={className}
      >
        {link.label}
      </Link>
    );
  }

  if (link._type === 'externalLink') {
    return (
      <OutboundLink
        href={link.url}
        onClick={() => trackEvent(link)}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {link.label}
      </OutboundLink>
    );
  }
};

const CTAButtons = ({ title, primaryBtn, secondaryBtn }) => {
  return (
    <div className="relative z-10 h-fit w-full overflow-hidden rounded-xl bg-smoky-black p-8 md:px-8 md:py-20">
      {/* background */}
      <div className="absolute -bottom-[30%] left-[25%] -z-10 h-2/3 w-2/3 bg-gradient-hero blur-[9rem]" />

      <div className="flex w-full flex-col items-center gap-4">
        <h3 className="text-center text-3xl font-medium leading-tight text-white md:w-[50%] md:text-4xl">
          <PortableText value={title} components={portableTextHeading as any} />
        </h3>
        <div className="flex w-full flex-col flex-nowrap items-center justify-center gap-4 pt-4 md:w-fit md:flex-row md:gap-10">
          <CTALink
            link={primaryBtn}
            className="w-full min-w-52 flex-1 whitespace-nowrap rounded-lg bg-white px-6 py-3 text-center text-base font-semibold text-primary-600 hover:opacity-80 md:w-fit"
          />
          {secondaryBtn && (
            <CTALink
              link={secondaryBtn}
              className="btn-gradient-border w-full min-w-52 flex-1 whitespace-nowrap !px-6 !py-3 text-center md:w-fit"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CTAButtons;
